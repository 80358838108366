import Cookies from "js-cookie";
import React from "react";
import { MdLogout } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import logo from '../../asset/images/LSS.png'

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const logOut = () => {
    Cookies.remove("userLoginData");
    Cookies.remove("deviceBrowserId");
    navigate("/");
  };
  const handlepath = () => {
    navigate("/dashboard");
  }
  return (
    <div className="relative z-50 w-full h-[80px] bg-white">
      <div className="fixed w-full bg-white">
        {location.pathname !== "/" ? (
          <div className="flex justify-start border-b border-[gray] ">
            <div className="max-w-[170px] h-[70px] overflow-hidden flex items-center pl-5">
              <img
                src={logo}
                alt=""
                srcset=""
                style={{ cursor: "pointer" }}
                onClick={handlepath}
              />
            </div>
          </div>
        ) : (
          <div className="flex justify-center border-b border-[gray] ">
            <div className="max-w-[170px] h-[70px] overflow-hidden flex items-center pl-5">
              <img
                src={logo}
                alt=""
                srcset=""
                style={{ cursor: "pointer" }}
                onClick={handlepath}
              />
            </div>
          </div>
        )}

        {location.pathname !== "/" && (
          <>
          <div
            className="absolute flex flex-auto space-x-1 cursor-pointer fleitems-center right-6 top-4"
          >
            <p className="bg-[#F4F7FB] text-[black] rounded-xl px-4 py-1.5" onClick={()=> {navigate('/browserId')}}>Device ID</p>
            <p className="flex items-center gap-1 bg-[#F4F7FB] text-[black] rounded-xl px-4 py-1.5" onClick={logOut}>
              Logout <MdLogout />
            </p>
          </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
