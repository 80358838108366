import React, { useEffect, useRef, useState } from "react";
import { useGeolocated } from "react-geolocated";
import axios from "axios";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { MdKeyboardBackspace } from "react-icons/md";
import { ApiEndpoints } from "../ApiEndpoints";
import { IoArrowBackOutline } from "react-icons/io5";
import { TiTick } from "react-icons/ti";
import { Backdrop, CircularProgress } from "@mui/material";
import Cookies from "js-cookie";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const VisitForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const cookieData = Cookies.get("userLoginData");
  const [nextStepOptions, setNextStepOptions] = useState([]);
  const [potentialDrugOptions, setPotentialDrugOptions] = useState([]);
  const [visitOptions, setVisitOptions] = useState([]);
  const [visitTypeOptions, setVisitTypeOptions] = useState([]);

  const [defaultDateTime, setDefaultDateTime] = useState(getCurrentDateTime());

  function getCurrentDateTime() {
    const now = new Date();
    // Convert UTC time to local time
    const localDateTime = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    );
    // Format the date and time to YYYY-MM-DDTHH:MM format required by datetime-local input
    const formattedDateTime = localDateTime.toISOString().slice(0, 16);
    return formattedDateTime;
  }

  const [followUpDate, setFollowUpDate] = useState(defaultDateTime);
  const [visitDateTime, setVisitDateTime] = useState(defaultDateTime);


  let data;
  if (cookieData !== undefined) {
    data = JSON.parse(cookieData);
  } else {
    console.log("User login data cookie does not exist or is undefined.");
  }
  useEffect(() => {
    if (cookieData === undefined) {
      navigate("/");
    } else {
      data = JSON.parse(cookieData);
      setNextStepOptions(data?.data?.Values["Next Steps"]);
      setPotentialDrugOptions(data?.data?.Values["Potential Drugs"]);
      setVisitOptions(data?.data?.Values["Visit Outcome"]);
      setVisitTypeOptions(data?.data?.Values["Visit Type"]);
    }
  }, [cookieData]);

  const [leadId, setLeadId] = useState(location?.state?.leadId);
  const [doctor_c, setDoctor_c] = useState(location?.state?.Doctor__c);
  const [createdWith, setCreatedWith] = useState(location?.state?.createdWith);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  // const [visitOptions, setVisitOptions] = useState([
  //   "Not Interested",
  //   "Doctor Not In",
  //   "Ineligible Practice Type",
  //   "Inconclusive",
  //   "Interested",
  //   "Existing Account",
  // ]);
  const [selectedVisitOption, setSelectedVisitOption] = useState("");
  useEffect(() => {
    // Automatically set Visit Outcome to "Existing Account" if doctor_c is not null
    if (doctor_c !== null && selectedVisitOption === "") {
      setSelectedVisitOption("Existing Account");
      setVisitDropdownOptions("Existing Account");
    }
  }, [doctor_c, selectedVisitOption]);

  const [VisitdropDown, setVisitDropdown] = useState(false);
  const [VisitdropDownOptions, setVisitDropdownOptions] = useState("");
  const handleVisitDropValue = (value) => {
    setSelectedVisitOption(value);
    setVisitDropdownOptions(value);
  };

  // const [visitTypeOptions, setVisitTypeOptions] = useState([
  //   "Cold Walk-In",
  //   "Follow Up",
  //   "Scheduled Visit",
  //   "Inservice",
  //   "Lunch",
  //   "Addressing Service Issue",
  // ]);
  const [visitTypedropDown, setVisitTypeDropdown] = useState(false);
  const [visitTypedropDownOptions, setVisitTypeDropdownOptions] = useState("");
  const handleVisitTypeDropValue = (item) => {
    setVisitTypeDropdownOptions(item);
  };

  // const [nextStepOptions, setNextStepOptions] = useState([
  //   "Deliver specialty specific prescription forms",
  //   "Does partial buy and bill",
  //   "Follow up call in (x) weeks with (Name)",
  //   "Has current pharmacy but willing to try us",
  //   "Routine follow up",
  //   "Schedule in-service visit",
  //   "Schedule meeting with customer service (support team)",
  //   "Schedule meeting with executive team",
  //   "Schedule one on one with office manager",
  //   "Schedule one on one with provider",
  //   "Schedule onsite visit",
  //   "Schedule portal demonstration",
  //   "Service Issue",
  //   "Set cadence for ongoing visits",
  //   "Set up cover my meds act and train staff",
  //   "Stop by with lunch",
  //   "Stop by with marketing materials",
  //   "Wants more information",
  //   "Wants rep to meet provider",
  //   "Wants rep to office manager",
  //   "Wants to meet leadership",
  // ]);

  const [nextStepDropDown, setNextStepDropDown] = useState(false);
  const [nextStepDropDownOptions, setNextStepDropdownOptions] = useState("");
  const handleNextStepDropValue = (value) => {
    setNextStepDropdownOptions(value);
  };

  const [lostReasonOptions, setLostReasonOptions] = useState([
    "Buy & Bill",
    "Happy With Current Pharmacy",
    "Non-Formulary Match",
    "Other",
  ]);

  const [lostReasondropDown, setLostReasonDropdown] = useState(false);
  const [lostReasonDropDownOptions, setLostReasonDropDownOptions] =
    useState("");
  const handleLostReasonDropValue = (value) => {
    setLostReasonDropDownOptions(value);
  };

  // const [potentialDrugOptions, setPotentialDrugOptions] = useState([
  //   "Eligard",
  //   "Firmagon",
  //   "Granix",
  //   "Humira",
  //   "Irinotecan",
  //   "Lupron",
  //   "Mitomycin",
  //   "Mvasi",
  //   "Oral Chemo Agents",
  //   "Orgovyx",
  //   "Prolia",
  //   "Remicaid",
  //   "Rituxan",
  //   "Simponi",
  //   "Stelara",
  //   "Trelstar",
  //   "Xtandi",
  //   "Zarxio",
  //   "Zytiga",
  // ]);

  const [potentialDrugDropDown, setPotentialDrugDropdown] = useState(false);
  const [potentialDrugDropDownOptions, setPotentialDrugDropDownOptions] =
    useState([]);
  // const handlePotentialDrugDropValue = (value) => {
  //   setPotentialDrugDropDownOptions(value);
  // };
  const handlePotentialDrugDropValue = (drug) => {
    const isSelected = potentialDrugDropDownOptions.includes(drug);
    if (isSelected) {
      setPotentialDrugDropDownOptions(
        potentialDrugDropDownOptions.filter((d) => d !== drug)
      );
    } else {
      setPotentialDrugDropDownOptions([...potentialDrugDropDownOptions, drug]);
    }
  };

  const [reoccurrenceOptions, setReoccurrenceOptions] = useState([
    "Weekly",
    "Bi-Weekly",
    "Monthly",
    "Bi-Monthly",
  ]);
  const [reoccurrenceDropDown, setReoccurrenceDropdown] = useState(false);
  const [reoccurrenceDropDownOptions, setReoccurrenceDropDownOptions] =
    useState("");

  const handleReoccurrenceDropValue = (value) => {
    setReoccurrenceDropDownOptions(value);
  };

  const [overallRatingOptions, setOverallRatingOptions] = useState([
    "Positive",
    "Neutral",
    "Negative",
  ]);
  const [overallRatingDropDown, setOverallRatingDropdown] = useState(false);
  const [overallRatingDropDownOptions, setOverallRatingDropDownOptions] =
    useState("");

  const handleOverallRatingDropValue = (value) => {
    setOverallRatingDropDownOptions(value);
  };

  const [ineligibleReasonOptions, setIneligibleReasonOptions] = useState([
    "Hospital",
    "Out of Business",
    "Buy & Bill",
  ]);
  const [ineligibleReasonDropDown, setIneligibleReasonDropdown] =
    useState(false);
  const [ineligibleReasonDropDownOptions, setIneligibleReasonDropDownOptions] =
    useState("");

  const handleIneligibleReasonDropValue = (value) => {
    setIneligibleReasonDropDownOptions(value);
  };

  const [serviceIssueOptions, setServiceIssueOptions] = useState([
    "Refill missed",
    "PA no follow up call",
    "Co Pay issue",
    "Non / untimely delivery",
    "Escript error",
    "Other",
  ]);

  const inputRef = useRef(null);

  const [serviceIssueDropdown, setServiceIssueDropdown] = useState(false);
  const [serviceIssueDropDownOptions, setServiceIssueDropDownOptions] =
    useState("");
  const handleServiceIssueDropValue = (value) => {
    setServiceIssueDropDownOptions(value);
  };

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  function checkPayloadValues(payload) {
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        const value = payload[key];
        if (value === null || value === undefined || value === "") {
          return false;
        }
      }
    }
    return true;
  }


  const handleVisitSubmit = async (event) => {
    event.preventDefault();

    setOpen(true);
    try {
      const frontendUserId = data?.data?.user_id;
      const timeZone = data?.data?.User_Timezone;
      const formData = new FormData(event.target);
      formData.set("Follow_Up_Date", followUpDate);
      formData.set("Visit_Date_Time", visitDateTime);

      if (createdWith === "Doctor" && nextStepDropDownOptions === ""){
        setNextStepDropdownOptions("Routine follow up");
        
      }

      // const browserId = Cookies.get("deviceBrowserId");

      const payload = {
        Visit_Create_By: createdWith,
        Visit_Date_Time__c: formData.get("Visit_Date_Time"),
        Visit_Type__c: visitTypedropDownOptions,
        Visit__c: VisitdropDownOptions,
        Comment__c: formData.get("Comment"),
        Follow_Up_Date__c: formData.get("Follow_Up_Date"),
        Reocurrance__c: reoccurrenceDropDownOptions,
        Next_Step__c: nextStepDropDownOptions,
        Lost_Reason__c: lostReasonDropDownOptions,
        Potential_Drugs__c: potentialDrugDropDownOptions.join(";"),
        Ineligible_Reason__c: ineligibleReasonDropDownOptions,
        Service_Issue__c: serviceIssueDropDownOptions,
        Service_Issue_Other__c: formData.get("Service_Issue_Other"),
        Longitude_and_Latitude__c: `${coords?.longitude},${coords?.latitude}`,
        Front_End_User__c: frontendUserId,
        Contact_Name__c: formData.get("Contact_Name__c"),
        Additional_Task_Items__c: formData.get("Additional_Task_Items__c"),
        Time_Zone__c: timeZone,
        Overall_Interaction_Rating__c: overallRatingDropDownOptions,
        // browserId: browserId
      };
      if (createdWith === "Doctor") {
        payload.Doctor__c = doctor_c;
        payload.Current_New_Patients__c =
          "How many new patients a month do they think they average?";
      } else if (createdWith === "Lead") {
        payload.Lead__c = leadId;
      }

      // const isPayloadValid = checkPayloadValues(payload);
      if (
        VisitdropDownOptions.length === 0 ||
        payload.Visit_Date_Time__c === "" ||
        !coords?.longitude ||
        !coords?.latitude ||
        (VisitdropDownOptions === "Not Interested" &&
          !lostReasonDropDownOptions) ||
        (VisitdropDownOptions === "Ineligible Practice Type" &&
          !ineligibleReasonDropDownOptions) ||
        (VisitdropDownOptions !== "Not Interested" &&
          VisitdropDownOptions !== "Existing Account" &&
          VisitdropDownOptions !== "Ineligible Practice Type" &&
          !nextStepDropDownOptions) ||
        !visitTypedropDownOptions ||
        (VisitdropDownOptions === "Interested" &&
          payload.Follow_Up_Date__c.length === 0) ||
        (VisitdropDownOptions !== "Existing Account" &&
          !nextStepDropDownOptions)
      ) {
        setOpen(false);

        toast.error(
          `Please fill out ${
            payload.Visit_Date_Time__c.length === 0
              ? "Visit Date Time"
              : "" ||
                (VisitdropDownOptions === "Not Interested" &&
                  !lostReasonDropDownOptions)
              ? "Lost Reason"
              : "" ||
                (VisitdropDownOptions === "Ineligible Practice Type" &&
                  !ineligibleReasonDropDownOptions)
              ? "Ineligible Reason"
              : "" ||
                (VisitdropDownOptions !== "Existing Account" &&
                  !nextStepDropDownOptions)
              ? "Next Step"
              : "" || !visitTypedropDownOptions
              ? "Visit Type"
              : "" ||
                (VisitdropDownOptions === "Interested" &&
                  payload.Follow_Up_Date__c.length === 0)
              ? "Follow Up Date"
              : ""
          }  field`,
          {
            position: "top-center",
            style: {
              // marginTop: "50px",
              color: "#9FB3F2",
            },
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          }
        );
        setLoading(false);
        return;
      }

      if (createdWith === "Lead" && payload.Comment__c === "") {
        // Make Comment mandetory for Visit by Lead
        setOpen(false);
        toast.error("Please fill out comment field", {
          position: "top-center",
          style: {
            // marginTop: "50px",
            color: "#9FB3F2",
          },
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setLoading(false);
        return;
      }

      const response = await axios.post(ApiEndpoints.VISIT_SUBMIT, payload, {
        headers: {
          Authorization: `Bearer ${data.access_token}`,
        },
      });

      setLoading(false);

      if (response?.data?.status === "success") {
        setOpen(false);

        toast.success("Submitted successfully!", {
          position: "top-center",
          style: {
            // marginTop: "50px",
            color: "#9FB3F2",
          },
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setTimeout(() => {
          if (createdWith === "Doctor") {
            navigate("/doctors");
          } else if (createdWith === "Lead") {
            navigate("/leads");
          }
        }, 2000);
      } else {
        setOpen(false);
        toast.error(response?.data?.message, {
          position: "top-center",
          style: {
            // marginTop: "50px",
            color: "#9FB3F2",
          },
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
      setLoading(false);
    } catch (error) {
      // Handle errors here
      if (error?.response?.data?.detail === "token_expired") {
        Cookies.remove("userLoginData");
        navigate("/");
      }
      toast.error("Something went wrong");
      setLoading(false);
      setOpen(false);
    }
  };

  const [inputType, setInputType] = useState("text");
  const handleMouseLeave = () => {
    setInputType("datetime-local");
  };
  const handleMouseEnter = () => {
    setInputType("datetime-local");
  };
  const [inputTypeF, setInputTypeF] = useState("text");
  const handleMouseLeaveF = () => {
    setInputTypeF("date");
  };
  const handleMouseEnterF = () => {
    setInputTypeF("date");
  };
  const visitTypeDropdownRef = useRef(null);
  const visitDropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        visitTypeDropdownRef.current &&
        !visitTypeDropdownRef.current.contains(event.target)
      ) {
        setVisitTypeDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visitTypeDropdownRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        visitDropdownRef.current &&
        !visitDropdownRef.current.contains(event.target)
      ) {
        setVisitDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visitDropdownRef]);
  const nextStepDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        nextStepDropdownRef.current &&
        !nextStepDropdownRef.current.contains(event.target)
      ) {
        setNextStepDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [nextStepDropdownRef]);

  const toggleDropdown = () => {
    setNextStepDropDown(!nextStepDropDown);
  };
  const lostReasonDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        lostReasonDropdownRef.current &&
        !lostReasonDropdownRef.current.contains(event.target)
      ) {
        setLostReasonDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [lostReasonDropdownRef]);
  const potentialDrugDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        potentialDrugDropdownRef.current &&
        !potentialDrugDropdownRef.current.contains(event.target)
      ) {
        setPotentialDrugDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [potentialDrugDropdownRef]);
  const serviceIssueDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        serviceIssueDropdownRef.current &&
        !serviceIssueDropdownRef.current.contains(event.target)
      ) {
        setServiceIssueDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [serviceIssueDropdownRef]);

  const reoccurrenceDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        reoccurrenceDropdownRef.current &&
        !reoccurrenceDropdownRef.current.contains(event.target)
      ) {
        setReoccurrenceDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [reoccurrenceDropdownRef]);

  const overallRatingDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        overallRatingDropdownRef.current &&
        !overallRatingDropdownRef.current.contains(event.target)
      ) {
        setOverallRatingDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [overallRatingDropdownRef]);
  

  const ineligibleReasonDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ineligibleReasonDropdownRef.current &&
        !ineligibleReasonDropdownRef.current.contains(event.target)
      ) {
        setIneligibleReasonDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ineligibleReasonDropdownRef]);

  return !isGeolocationAvailable ? (
    <div className="flex justify-center mt-6">
      Your browser does not support Geolocation
    </div>
  ) : !isGeolocationEnabled ? (
    <div className="flex flex-col items-center justify-center px-4 py-4 mx-3 mt-6 bg-white shadow sm:rounded-lg sm:px-10">
      <p>Location is Not Allowed. You can not log visits until this enabled</p>
      <h3 className="mt-4 mb-3 font-bold">To enable location</h3>
      <p className="my-3">
        Click on three dots (Site Home Icon) -&gt; Settings -&gt; Privacy and
        security -&gt; Site settings -&gt; Location -&gt; Enable
      </p>
      <div className="mt-4">
        <button
          type="button"
          class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 flex flex-row justify-center items-center"
          onClick={() => navigate("/leads")}
        >
          <IoArrowBackOutline className="me-2" />
          Go Back
        </button>
      </div>
    </div>
  ) : coords ? (
    <>
      <ToastContainer />

      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-[900px] ">
          <MdKeyboardBackspace
            size={20}
            className="ml-4 cursor-pointer"
            onClick={() => navigate(-1)}
          />
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-[700px] ">
          <div className="px-6 py-8 mx-4 bg-white shadow sm:rounded-lg sm:px-10">
            <h1 className="flex justify-center mt-2 mb-6 text-xl font-bold mb">
              Visit Form
            </h1>
            <form onSubmit={handleVisitSubmit}>
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
                {createdWith === "Lead" && (
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Visit Outcome
                    </label>
                    <div
                      ref={visitDropdownRef}
                      onClick={() => {
                        setVisitDropdown(!VisitdropDown);
                        setVisitTypeDropdown(false);
                        setNextStepDropDown(false);
                        setLostReasonDropdown(false);
                        setPotentialDrugDropdown(false);
                        setServiceIssueDropdown(false);
                        setIneligibleReasonDropdown(false);
                        setReoccurrenceDropdown(false);
                        setVisitTypeDropdown(false);
                        setOverallRatingDropdown(false);
                      }}
                      className="relative w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    >
                      {selectedVisitOption !== ""
                        ? selectedVisitOption
                        : "Select Options"}
                      {VisitdropDown ? (
                        <div className="absolute w-full bg-[#fff] top-12 left-0 shadow-[rgba(149,157,165,0.2)_0px_8px_24px] z-[1] max-h-40 overflow-y-scroll">
                          {visitOptions.map((item, index) => (
                            <p
                              key={index}
                              onClick={() => handleVisitDropValue(item)}
                              className="px-8 py-2 hover:bg-gray-300"
                            >
                              {item}
                            </p>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Visit Type
                  </label>
                  <div
                    ref={visitTypeDropdownRef}
                    onClick={() => {
                      setVisitTypeDropdown(!visitTypedropDown);
                      setNextStepDropDown(false);
                      setLostReasonDropdown(false);
                      setPotentialDrugDropdown(false);
                      setServiceIssueDropdown(false);
                      setIneligibleReasonDropdown(false);
                      setReoccurrenceDropdown(false);

                      setVisitDropdown(false);
                      setOverallRatingDropdown(false);
                    }}
                    className="relative w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  >
                    {visitTypedropDownOptions !== ""
                      ? visitTypedropDownOptions
                      : "Select Options"}
                    {visitTypedropDown ? (
                      <div className="absolute w-full bg-[#fff] top-12 left-0 shadow-[rgba(149,157,165,0.2)_0px_8px_24px] z-[1] max-h-40 overflow-y-scroll">
                        {visitTypeOptions.map((item, index) => (
                          <p
                            key={index}
                            onClick={() => handleVisitTypeDropValue(item)}
                            className="px-8 py-2 hover:bg-gray-300"
                          >
                            {item}
                          </p>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Visit Date Time
                  </label>
                  <div className="relative mt-1">
                    <input
                      type="datetime-local"
                      name="Visit_Date_Time"
                      value={visitDateTime}
                      onChange={(e) => {
                        setVisitDateTime(e.target.value);
                      }}
                      placeholder="dd/mm/yyyy"
                      className="block w-full px-3 py-2 placeholder-gray-400 bg-transparent border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Next Step
                  </label>
                  <div
                    ref={nextStepDropdownRef}
                    onClick={() => {
                      setNextStepDropDown(!nextStepDropDown);
                      setLostReasonDropdown(false);
                      setPotentialDrugDropdown(false);
                      setServiceIssueDropdown(false);
                      setIneligibleReasonDropdown(false);
                      setReoccurrenceDropdown(false);
                      setVisitTypeDropdown(false);
                      setVisitDropdown(false);
                      setOverallRatingDropdown(false);
                    }}
                    className="relative w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  >
                    {nextStepDropDownOptions !== ""
                      ? nextStepDropDownOptions
                      : "Select Options"}
                    {nextStepDropDown ? (
                      <div className="absolute w-full bg-[#fff] top-12 left-0 shadow-[rgba(149,157,165,0.2)_0px_8px_24px] z-[1] max-h-40 overflow-y-scroll">
                        {nextStepOptions.map((item, index) => (
                          <p
                            key={index}
                            onClick={() => handleNextStepDropValue(item)}
                            className="px-8 py-2 hover:bg-gray-300"
                          >
                            {item}
                          </p>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>

                {VisitdropDownOptions === "Not Interested" && (
                  <div>
                    <label
                      htmlFor=""
                      className="block text-sm font-medium text-gray-700"
                    >
                      Lost Reason
                    </label>
                    <div
                      ref={lostReasonDropdownRef}
                      onClick={() => {
                        setLostReasonDropdown(!lostReasondropDown);
                        setPotentialDrugDropdown(false);
                        setServiceIssueDropdown(false);
                        setIneligibleReasonDropdown(false);
                        setReoccurrenceDropdown(false);

                        setNextStepDropDown(false);
                        setVisitTypeDropdown(false);
                        setVisitDropdown(false);
                        setOverallRatingDropdown(false);
                      }}
                      className="relative w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    >
                      {lostReasonDropDownOptions !== ""
                        ? lostReasonDropDownOptions
                        : "Select Options"}
                      {lostReasondropDown ? (
                        <div className="absolute w-full bg-[#fff] top-12 left-0 shadow-[rgba(149,157,165,0.2)_0px_8px_24px] z-[1] max-h-40 overflow-y-scroll">
                          {lostReasonOptions.map((item, index) => (
                            <p
                              key={index}
                              onClick={() => handleLostReasonDropValue(item)}
                              className="px-8 py-2 hover:bg-gray-300"
                            >
                              {item}
                            </p>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}

                {createdWith === "Lead" && (
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Potential Drugs
                    </label>
                    <div
                      ref={potentialDrugDropdownRef}
                      onClick={() => {
                        setPotentialDrugDropdown(!potentialDrugDropDown);
                        setServiceIssueDropdown(false);
                        setIneligibleReasonDropdown(false);
                        setReoccurrenceDropdown(false);
                        setLostReasonDropdown(false);
                        setNextStepDropDown(false);
                        setVisitTypeDropdown(false);
                        setVisitDropdown(false);
                        setOverallRatingDropdown(false);
                      }}
                      className="relative w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    >
                      {potentialDrugDropDownOptions.length > 0
                        ? potentialDrugDropDownOptions.join(", ")
                        : "Select Options"}
                      {potentialDrugDropDown ? (
                        <div className="absolute w-full bg-[#fff] top-12 left-0 shadow-[rgba(149,157,165,0.2)_0px_8px_24px] z-[1] max-h-40 overflow-y-scroll">
                          {potentialDrugOptions.map((item, index) => (
                            <p
                              key={index}
                              onClick={() => handlePotentialDrugDropValue(item)}
                              className="flex flex-row justify-between px-8 py-2 hover:bg-gray-300"
                            >
                              {item}
                              {potentialDrugDropDownOptions.includes(item) && (
                                <span>
                                  <TiTick />
                                </span>
                              )}
                            </p>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}

                {visitTypedropDownOptions === "Addressing Service Issue" && (
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Service Issue
                    </label>
                    <div
                      ref={serviceIssueDropdownRef}
                      onClick={() => {
                        setServiceIssueDropdown(!serviceIssueDropdown);
                        setIneligibleReasonDropdown(false);
                        setReoccurrenceDropdown(false);
                        setPotentialDrugDropdown(false);
                        setLostReasonDropdown(false);
                        setNextStepDropDown(false);
                        setVisitTypeDropdown(false);
                        setVisitDropdown(false);
                        setOverallRatingDropdown(false);
                      }}
                      className="relative w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    >
                      {serviceIssueDropDownOptions !== ""
                        ? serviceIssueDropDownOptions
                        : "Select Options"}
                      {serviceIssueDropdown ? (
                        <div className="absolute w-full bg-[#fff] top-12 left-0 shadow-[rgba(149,157,165,0.2)_0px_8px_24px] z-[1] max-h-40 overflow-y-scroll">
                          {serviceIssueOptions.map((item, index) => (
                            <div
                              key={index}
                              onClick={() => handleServiceIssueDropValue(item)}
                              className="px-8 py-2 hover:bg-gray-300"
                            >
                              {item}
                            </div>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}

                {createdWith === "Lead" && (
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Reocurrance
                    </label>
                    <div
                      ref={reoccurrenceDropdownRef}
                      onClick={() => {
                        setReoccurrenceDropdown(!reoccurrenceDropDown);
                        setIneligibleReasonDropdown(false);
                        setServiceIssueDropdown(false);
                        setPotentialDrugDropdown(false);
                        setLostReasonDropdown(false);
                        setNextStepDropDown(false);
                        setVisitTypeDropdown(false);
                        setVisitDropdown(false);
                        setOverallRatingDropdown(false);
                      }}
                      className="relative w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    >
                      {reoccurrenceDropDownOptions !== ""
                        ? reoccurrenceDropDownOptions
                        : "Select Options"}
                      {reoccurrenceDropDown ? (
                        <div className="absolute w-full bg-[#fff] top-12 left-0 shadow-[rgba(149,157,165,0.2)_0px_8px_24px] z-[1] max-h-40 overflow-y-scroll">
                          {reoccurrenceOptions.map((item, index) => (
                            <p
                              key={index}
                              onClick={() => handleReoccurrenceDropValue(item)}
                              className="px-8 py-2 hover:bg-gray-300"
                            >
                              {item}
                            </p>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}

                {createdWith === "Lead" && (
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Overall Interaction Rating
                    </label>
                    <div
                      ref={overallRatingDropdownRef}
                      onClick={() => {
                        setOverallRatingDropdown(!overallRatingDropDown);
                        setReoccurrenceDropdown(false);
                        setIneligibleReasonDropdown(false);
                        setServiceIssueDropdown(false);
                        setPotentialDrugDropdown(false);
                        setLostReasonDropdown(false);
                        setNextStepDropDown(false);
                        setVisitTypeDropdown(false);
                        setVisitDropdown(false);
                      }}
                      className="relative w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    >
                      {overallRatingDropDownOptions !== ""
                        ? overallRatingDropDownOptions
                        : "Select Options"}
                      {overallRatingDropDown ? (
                        <div className="absolute w-full bg-[#fff] top-12 left-0 shadow-[rgba(149,157,165,0.2)_0px_8px_24px] z-[1] max-h-40 overflow-y-scroll">
                          {overallRatingOptions.map((item, index) => (
                            <p
                              key={index}
                              onClick={() => handleOverallRatingDropValue(item)}
                              className="px-8 py-2 hover:bg-gray-300"
                            >
                              {item}
                            </p>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}

                {VisitdropDownOptions === "Ineligible Practice Type" && (
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Ineligible Reason
                    </label>
                    <div
                      ref={ineligibleReasonDropdownRef}
                      onClick={() => {
                        setIneligibleReasonDropdown(!ineligibleReasonDropDown);
                        setReoccurrenceDropdown(false);
                        setServiceIssueDropdown(false);
                        setPotentialDrugDropdown(false);
                        setLostReasonDropdown(false);
                        setNextStepDropDown(false);
                        setVisitTypeDropdown(false);
                        setVisitDropdown(false);
                        setOverallRatingDropdown(false);
                      }}
                      className="relative w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    >
                      {ineligibleReasonDropDownOptions !== ""
                        ? ineligibleReasonDropDownOptions
                        : "Select Options"}
                      {ineligibleReasonDropDown ? (
                        <div className="absolute w-full bg-[#fff] top-12 left-0 shadow-[rgba(149,157,165,0.2)_0px_8px_24px] z-[1] max-h-40 overflow-y-scroll">
                          {ineligibleReasonOptions.map((item, index) => (
                            <p
                              key={index}
                              onClick={() =>
                                handleIneligibleReasonDropValue(item)
                              }
                              className="px-8 py-2 hover:bg-gray-300"
                            >
                              {item}
                            </p>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Follow Up Date
                  </label>
                  <div className="relative mt-1">
                    <input
                      name="Follow_Up_Date"
                      type="datetime-local"
                      value={followUpDate}
                      onChange={(e) => {
                        setFollowUpDate(e.target.value);
                      }}
                      placeholder="dd/mm/yyyy"
                      className="block w-full px-3 py-2 placeholder-gray-400 bg-transparent border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>

                {serviceIssueDropDownOptions === "Other" &&
                  visitTypedropDownOptions === "Addressing Service Issue" && (
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Service Issue Other
                      </label>
                      <div className="relative mt-1">
                        <input
                          type="text"
                          name="Service_Issue_Other"
                          placeholder="Enter Other Service Issue"
                          className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  )}

                {nextStepDropDownOptions === "Follow up call" && (
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Contact Name
                    </label>
                    <div className="relative mt-1">
                      <input
                        type="text"
                        name="Contact_Name__c"
                        placeholder="Enter contact name"
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                )}
              </div>

              {followUpDate && (
                <div>
                  <label
                    htmlFor="password"
                    className="block mt-3 text-sm font-medium text-gray-700"
                  >
                    Additional Items For Task
                  </label>
                  <div className="relative mt-1">
                    <input
                      type="text"
                      name="Additional_Task_Items__c"
                      placeholder="Enter additional items"
                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
              )}

              <div>
                <label
                  htmlFor="password"
                  className="block mt-2 text-sm font-medium text-gray-700"
                >
                  Longitude & Lattitude
                </label>
                <div className="relative mt-1">
                  <input
                    type="text"
                    name="Latitude__c"
                    value={`${coords.longitude.toFixed(
                      2
                    )}, ${coords.latitude.toFixed(2)}`}
                    disabled
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="comment"
                  className="block mt-4 text-sm font-medium text-gray-700"
                >
                  Comment
                </label>
                <div className="mt-1">
                  <textarea
                    name="Comment"
                    placeholder="Enter Comment"
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  ></textarea>
                </div>
              </div>

              <div className="w-full my-4 text-center">
                {loading ? (
                  <button
                    disabled
                    type="button"
                    class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 inline-flex items-center"
                  >
                    Submitting
                    <svg
                      aria-hidden="true"
                      role="status"
                      class="inline w-4 h-4 me-3 text-white animate-spin ms-2"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="text-white bg-gray-700 hover:bg-gray-800 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-7 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700"
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  ) : (
    <div className="flex justify-center mt-6">
      <p>Getting the location data&hellip; </p>
    </div>
  );
};

export default VisitForm;
