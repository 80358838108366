import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { FiCopy } from "react-icons/fi";
import Cookies from "js-cookie";

function BrowserId() {

    const [deviceId, setDeviceId] = useState(null);

    useEffect(() => {
        const browserId = Cookies.get("deviceBrowserId");
        setDeviceId(browserId)
    }, []);

    const handleCopy = () => {
        navigator.clipboard.writeText(deviceId);
        toast.success("Device ID copied to clipboard!");
    };

    return (
        <>
            <ToastContainer
                position="top-center" 
                autoClose={1000} 
                hideProgressBar={true}
                closeOnClick
                pauseOnHover
                draggable
            />
            <div className="flex items-center justify-center h-[90vh]">
                <div className="px-4 text-center">
                    <h2 className="mb-4 text-xl font-bold">Device Authorization</h2>
                    <div className="flex items-center justify-center gap-2">
                        <p className="mb-2">
                            <strong>Device ID:</strong> {deviceId}
                        </p>
                        <button
                            className="p-1 bg-gray-100 rounded hover:bg-gray-200"
                            onClick={handleCopy}
                        >
                            <FiCopy size={20} />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BrowserId;
