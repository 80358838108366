import { React, useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Bars } from "react-loader-spinner";
import Cookies from "js-cookie";
import { ApiEndpoints } from "../ApiEndpoints";

const Login = ({ setLogged }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  
  const generateFingerprint = async () => {
    const nav = window.navigator;
    const screen = window.screen;
    let fingerprint = '';

    fingerprint += nav.userAgent;
    fingerprint += nav.language;
    fingerprint += screen.colorDepth;
    fingerprint += screen.width + 'x' + screen.height;
    fingerprint += new Date().getTimezoneOffset();
    fingerprint += nav.hardwareConcurrency;
    fingerprint += nav.deviceMemory || '';
    fingerprint += nav.userAgentData?.platform || nav.platform;
    fingerprint += nav.userAgentData?.mobile || /Mobi|Android/i.test(nav.userAgent);

    // Use SubtleCrypto to hash the fingerprint
    const encoder = new TextEncoder();
    const data = encoder.encode(fingerprint);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  };

  const loginUser = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        ApiEndpoints.LOGIN,
        {
          email: email,
          password: password,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.data?.status === "success") {
        Cookies.set("userLoginData", JSON.stringify(response.data));
        const browserId = await generateFingerprint();
        Cookies.set("deviceBrowserId", browserId);
        setLoading(false);
        setLogged(true);
        navigate("/dashboard");
        // toast.success("Login successfully");
      } else {
        setLoading(false);
        toast.error(response?.data?.error, {
          position: "top-center",
          style: {
            // marginTop: "50px",
            color: "#9FB3F2",
          },
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (error) {
      // Handle errors here
      console.error("Error during login:", error);
      toast.error(error, {
        position: "top-center",
        style: {
          // marginTop: "50px",
          color: "#9FB3F2",
        },
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    const cookieValue = Cookies.get("userLoginData");
    // Check if cookie exists
    if (cookieValue) {
      // Redirect to another page
      navigate("/leads");
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="px-4 py-8 mx-3 bg-white shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={loginUser}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="relative mt-1">
                  <input
                    type={visible ? "text" : "password"}
                    name="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  {visible ? (
                    <AiOutlineEye
                      className="absolute cursor-pointer right-2 top-2"
                      size={25}
                      onClick={() => setVisible(false)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="absolute cursor-pointer right-2 top-2"
                      size={25}
                      onClick={() => setVisible(true)}
                    />
                  )}
                </div>
              </div>
              <div className={`flex justify-between`}>
                <div className={`flex`}>
                  <input
                    type="checkbox"
                    name="remember-me"
                    id="remember-me"
                    className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                  />
                  <label
                    htmlFor="remember-me"
                    className="block ml-2 text-sm text-gray-900"
                  >
                    {" "}
                    Remember me
                  </label>
                </div>
                <div className="text-sm">
                  <a
                    href="forgot-password"
                    className="font-medium text-red-600 hover:text-red-500"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  disabled={loading ? true : false}
                  type="submit"
                  className="group relative w-[130px]  h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm rounded-md text-white bg-[#474747] font-semibold"
                >
                  {loading ? (
                    <Bars
                      height="30"
                      width="30"
                      color="#ffff"
                      ariaLabel="bars-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
              <div className={`flex w-full justify-center`}>
                <h4>Don't have an account?</h4>
                <Link to="/sign-up" className="pl-2 text-red-600 underline">
                  Register
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
