import { BrowserRouter, Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import LoginForm from './pages/Login';
import Header from './components/Layout/Header';
import Leads from './pages/Leads';
import VisitForm from './pages/VisitForm';
import PrivateComponent from "./PrivateComponent";
import { useState } from 'react';
import Dashboard from './pages/Dashboard';
import Doctors from './pages/Doctors';
import BrowserId from './pages/BrowserId';

function App() {
  const[logged,setLogged]=useState(false)
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<LoginForm setLogged={setLogged} />} />
          <Route element={<PrivateComponent />}>
            <Route
              path="/dashboard"
              element={<Dashboard isLogged={logged} setLogged={setLogged} />}
            />
            <Route path="/leads" element={<Leads />} />
            <Route path="/visit-form" element={<VisitForm />} />
            <Route path="/doctors" element={<Doctors />} />
            <Route path="/browserId" element={<BrowserId />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
